import { OfflinePostStore_I } from "@@addons/interfaces/database/offline_post";
import { AppDatabase } from "..";

export class OfflinePostStore extends AppDatabase<OfflinePostStore_I>{
  private db = this;
  public constructor() {
    super();

    this.storeTable = this.db.OfflineDBPosts__;
  }
}