import { APP_SETTINGS } from "./settings";

let _BASE_URL: string, _API_BASE_URL: string,
  _AKWAABA_API_BASE_URL: string, _FILE_BUCKET_BASE_URL: string,
  _HOME_PAGE_BASE_URL: string, _LOGIN_PAGE_BASE_URL: string,
  _PUBLIC_BASE_URL: string, _PUBLIC_PAGE_BASE_URL: string,
  _PUBLIC_LOGIN_PAGE_BASE_URL: string,
  __clientIdentifier: string;

if (APP_SETTINGS.production) {
  // const BaseUrl = "akwaaba.akwaabasoftware.com/"; correct
  const BaseUrl = "akwaabasoftware.com/"; //wrong change later
  // __clientIdentifier = "akwaaba.akwaabasoftware.com/";
  // __clientIdentifier = "clients." + BaseUrl; correct
  __clientIdentifier = "adminform." + BaseUrl; //wrong change later
  _BASE_URL = "https://" + __clientIdentifier;
  // _PUBLIC_BASE_URL = "https://app.akwaabasoftware.com/";
  // _PUBLIC_BASE_URL = "https://app.akwaaba.akwaabasoftware.com/"; correct
  _PUBLIC_BASE_URL = "https://userform." + BaseUrl; //wrong change later
  _API_BASE_URL = "https://api.akwaabasoftware.com/";
  _AKWAABA_API_BASE_URL = "https://akwaaba-forms-api." + BaseUrl;
  _FILE_BUCKET_BASE_URL = "https://file-bucket.akwaabasoftware.com/";
  _HOME_PAGE_BASE_URL = _BASE_URL + "";
  _PUBLIC_PAGE_BASE_URL = _PUBLIC_BASE_URL + "";
} else {
  __clientIdentifier = "akwaaba-forms/";
  // const IP_ADDRESS = '192.168.43.161';
  const IP_ADDRESS = '192.168.173.1';
  _BASE_URL = "http://127.0.0.1:9000/";
  _API_BASE_URL = "http://" + IP_ADDRESS + "/AMG/plus_db/api/";
  _AKWAABA_API_BASE_URL = "http://127.0.0.1:9000/api/";
  _FILE_BUCKET_BASE_URL = "http://" + IP_ADDRESS + "/AMG/plus_db/file_bucket/";
  _HOME_PAGE_BASE_URL = _BASE_URL + __clientIdentifier;
  _PUBLIC_PAGE_BASE_URL = _BASE_URL + "akwaaba-forms-public/";
}

// _LOGIN_PAGE_BASE_URL = _HOME_PAGE_BASE_URL + "login/";
// _PUBLIC_LOGIN_PAGE_BASE_URL = _PUBLIC_PAGE_BASE_URL + "login/";
_LOGIN_PAGE_BASE_URL = getLoginUrl(__clientIdentifier, _HOME_PAGE_BASE_URL, _PUBLIC_PAGE_BASE_URL);
export const URLS = {
  BASE_URL: _BASE_URL,
  API_BASE_URL: _API_BASE_URL,
  AKWAABA_API_BASE_URL: _AKWAABA_API_BASE_URL,
  FILE_BUCKET_BASE_URL: _FILE_BUCKET_BASE_URL,
  HOME_PAGE_BASE_URL: _HOME_PAGE_BASE_URL,
  PUBLIC_PAGE_BASE_URL: _PUBLIC_PAGE_BASE_URL,
  LOGIN_PAGE_BASE_URL: _LOGIN_PAGE_BASE_URL,
  PUBLIC_LOGIN_PAGE_BASE_URL: _PUBLIC_LOGIN_PAGE_BASE_URL,
  PUBLIC_BASE_URL: "https://akwaabasoftware.com/",
  CLICK_COM_BASE_URL: "https://clickcomgh.com/",
  // SERVICE_WORKER: SERVICE_WORKER
}

function getLoginUrl(clientIdentifier: string, _clientUrl: string, _publicUrl: string) {
  let url = ``;
  if (window.location.href.includes(clientIdentifier)) {
    url = _clientUrl;
  } else {
    url = _publicUrl;
  }
  return `${url}login/`; 
}
