import { ERROR_MSG } from "./error_msg";
import { IMAGES } from "./images";
import { NETWORK_CALLS } from "./network_calls";
import { AUTHORIZATION } from "./network_calls/auth";
import { SERVICE_WORKER_TYPES } from "./service_worker/messenger_types";
import { APP_SETTINGS } from "./settings";
import { URLS } from "./urls";

export const CONSTANTS = {
  IMAGES,
  // HTTPS,
  URLS,
  ERROR_MSG,
  APP_SETTINGS,
  SERVICE_WORKER_TYPES,
  NETWORK_CALLS,
  AUTHORIZATION,
}
// console.log({ CONSTANTS });
// @ts-ignore
window['CONSTANTS'] = CONSTANTS;
