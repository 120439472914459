// import { GET_UserMenus } from '../../network/menus';
// import { GET_Projects } from '../../network/projects/projects';
// import { LoginProfileProvider } from '../../mobx-providers/login';

import { POST_ClientLogin_I } from "@@addons/interfaces/network/login/client";
import { POST_ClientLogin } from "@@addons/network/login/client";

export class NetWorkCalls {
  /**
   * POST_ClientLogin
   */
  public clientLogin(parameters: POST_ClientLogin_I) {
    return POST_ClientLogin(parameters);
  }

  // /**
  //  * GET_UserMenus
  //  */
  // public userMenus(loginProfileProvider: LoginProfileProvider) {
  //   return GET_UserMenus(loginProfileProvider);
  // }

  /**
   * GET_Projects
   */
  // public getProjects(limit?: number, offset?: number) {
  //   return GET_Projects(limit, offset);
  // }
}