import { APP_SETTINGS } from '@@addons/constants/settings';
import { ApDbCallback_I } from '@@addons/interfaces/database';
import Dexie, { Collection, Table, WhereClause } from 'dexie';

export class AppDatabase<Type> extends Dexie {
  private DbSettings = APP_SETTINGS.database;
  public LoginDB__: Table<Type, number>; // id is number in this case
  public OfflineDBPosts__: Table<Type, number>; // id is number in this case
  public MenuItemsDBPosts__: Table<Type, number>; // id is number in this case
  public ProjectsDB__: Table<Type, number>; // id is number in this case
  public ProjectQuestionnairesDB__: Table<Type, number>; // id is number in this case
  public ProjectQuestionnaireTypesDB__: Table<Type, number>; // id is number in this case
  public ProjectQuestionnaireFormGroupsDB__: Table<Type, number>; // id is number in this case
  public ProjectQuestionnaireAgeBracketsDB__: Table<Type, number>; // id is number in this case
  public ProjectQuestionnairePositionDescriptionsDB__: Table<Type, number>; // id is number in this case
  public QuestionTypesDB__: Table<Type, number>; // id is number in this case
  public ProjectQuestionnaireQuestionsDB__: Table<Type, number>; // id is number in this case
  public ProjectQuestionOptionsDB__: Table<Type, number>; // id is number in this case
  public RedirectToDB__: Table<Type, number>; // id is number in this case
  public ProjectQuestionnaireQuestionResponsesDB__: Table<Type, number>; // id is number in this case
  public QuestionnaireResponsesDB__: Table<Type, number>; // id is number in this case
  public WalletInvoiceDB__: Table<Type, number>; // id is number in this case
  public DashboardReportDB__: Table<Type, number>; // id is number in this case

  protected storeTable: Table<Type, number>;

  public constructor() {

    super(APP_SETTINGS.database.databaseName);
    // console.log({"APP_SETTINGS.database.databaseName": APP_SETTINGS.database.databaseName});

    this.version(APP_SETTINGS.database.databaseVersion).stores(
      this.processStores()
    );
  }

  private processStores() {
    let stores = {};
    this.DbSettings.stores.list.forEach((store) => {
      let indiciesString = "";
      store.indicies.forEach((index) => {
        const __unique = index.unique === true ? "&" : "";
        const __multiEntry = index.multiEntry === true ? "*" : "";
        const __autoIncrement = index.autoIncrement === true ? "++" : "";
        const _front = `${__unique}${__multiEntry}${__autoIncrement}`;

        const _back = ", ";
        indiciesString += _front + index.name + _back;
      });
      indiciesString = indiciesString.substring(0, indiciesString.length - 2);

      // @ts-ignore
      stores[store.storeName] = indiciesString;
    });
    // console.log({ stores });

    return stores;
  }

  /**
   * get
   */
  public async getOne(
    id: number,
    callback?: ApDbCallback_I<Type>) {
    id = Number(id);
    await this.transaction('readwrite', this.storeTable, async () => {
      let item = await this.storeTable.get(id);
      if (item !== undefined) {
        callback(item);
      }
    }).catch(e => {
      console.error(e.stack || e);
    });
  }

  /**
   * get all
   */
  public async getAll(
    callback?: ApDbCallback_I<Type[]>) {
    // console.log({"this.storeTable": this.storeTable});

    await this.transaction('readwrite', this.storeTable, async () => {
      const items = await this.storeTable.toArray();

      callback(items);
    }).catch(e => {
      console.error(e.stack || e);
    });
  }

  // /**
  //  * get doWhere
  //  */
  // public async doWhere(
  //   index: string | string[],
  //   callback?: ApDbCallback_I<WhereClause<Type, number>>) {

  //   await this.transaction('readwrite', this.storeTable, async () => {
  //     const items = await this.storeTable.where(index);

  //     callback(items);
  //   }).catch(e => {
  //     console.error(e.stack || e);
  //   });
  // }

  /**
   * get doWhere
   */
  public async doWhere(
    equalityCriterias: {
      [key: string]: any;
    },
    callback?: ApDbCallback_I<Type[]>) {

    await this.transaction('readwrite', this.storeTable, async () => {
      // console.log({equalityCriterias});

      const items = await this.storeTable.where(equalityCriterias).toArray();

      callback(items);
    }).catch(e => {
      console.error(e.stack || e);
    });
  }

  /**
   * make post
   */
  public async makePost(
    object: Type[],
    callback?: ApDbCallback_I<number>) {

    await this.transaction('readwrite', this.storeTable, async () => {
      let id = 0;
      if (object.length > 0) {
        id = await this.storeTable.bulkPut(object);

      }
      callback(id);
    }).catch(e => {
      console.error(e.stack || e);
    });
  }

  /**
   * update
   */
  public async doUpdate(
    id: number, data: Type,
    callback?: ApDbCallback_I<number>) {
    id = Number(id);
    console.log("-----------------------", { id, data });
    await this.transaction('readwrite', this.storeTable, async () => {
      const updated = await this.storeTable.where({ id: id }).modify(data);

      callback(updated);
    }).catch(e => {
      console.error(e.stack || e);
    });
  }

  /**
   * delete
   */
  public async removeFrom(
    id: number,
    callback?: ApDbCallback_I<boolean>) {
    id = Number(id);
    await this.transaction('readwrite', this.storeTable, async () => {
      const del = await this.storeTable.delete(id);
      // console.log({del, id});
      console.log({"this.storeTable": this.storeTable});
      

      callback(true);
    }).catch(e => {
      console.error(e.stack || e);
    });
  }

  /**
   * delete all
   */
  public async removeAll() {
    console.log("--removeAll--", { "removeAll": "removeAll" });
    await this.getAll((response) => {
      console.log("--response--", { response });

      response.forEach(async res => {
        console.log("--res--", { res });
        await this.transaction('readwrite', this.storeTable, async () => {
          // @ts-ignore
          await this.storeTable.delete(res.id);
        })
      })
    });
  }

}

// db.version(1).stores({
//   friends: "++id,name,age,*tags",
//   gameSessions: "id,score"
// });

// db.version(2).stores({
//   friends: "++id, [firstName+lastName], yearOfBirth, *tags", // Change indexes
//   gameSessions: null // Delete table

// }).upgrade(tx => {
//   // Will only be executed if a version below 2 was installed.
//   return tx.table("friends").modify(friend => {
//       friend.firstName = friend.name.split(' ')[0];
//       friend.lastName = friend.name.split(' ')[1];
//       friend.birthDate = new Date(new Date().getFullYear() - friend.age, 0);
//       delete friend.name;
//       delete friend.age;
//   });
// });
