
const databaseStores = [
  {
    storeName: "LoginDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "RedirectToDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "OfflineDBPosts__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "url", keyPath: "url", unique: false,
        autoIncrement: false, multiEntry: false,
      }, {
        name: "date", keyPath: "date", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "MenuItemsDBPosts__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "name", keyPath: "name", unique: false,
        autoIncrement: false, multiEntry: false,
      }, {
        name: "module", keyPath: "module", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "ProjectsDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "name", keyPath: "name", unique: false,
        autoIncrement: false, multiEntry: false,
      }, {
        name: "date", keyPath: "date", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "ProjectQuestionnairesDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "projectId", keyPath: "projectId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "typeId", keyPath: "typeId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "formId", keyPath: "formId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "specialId", keyPath: "specialId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "date", keyPath: "date", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "ProjectQuestionnaireTypesDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "name", keyPath: "name", unique: false,
        autoIncrement: false, multiEntry: false,
      }, {
        name: "date", keyPath: "date", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "ProjectQuestionnaireFormGroupsDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "name", keyPath: "name", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "ProjectQuestionnaireAgeBracketsDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "name", keyPath: "name", unique: false,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "projectId", keyPath: "projectId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "ProjectQuestionnairePositionDescriptionsDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "name", keyPath: "name", unique: false,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "projectId", keyPath: "projectId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "QuestionTypesDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "name", keyPath: "name", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "ProjectQuestionOptionsDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "response", keyPath: "response", unique: false,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "questionId", keyPath: "questionId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "ProjectQuestionnaireQuestionsDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "formId", keyPath: "formId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "questionId", keyPath: "questionId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "ProjectQuestionnaireQuestionResponsesDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "clientId", keyPath: "clientId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "memberId", keyPath: "memberId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "questionId", keyPath: "questionId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "accountQuestionnaire", keyPath: "accountQuestionnaire", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "QuestionnaireResponsesDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "accountId", keyPath: "accountId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "accountTypeId", keyPath: "accountTypeId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "questionnaireId", keyPath: "questionnaireId", unique: false,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "paid", keyPath: "paid", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "WalletInvoiceDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "invoiceNumber", keyPath: "invoiceNumber", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
  {
    storeName: "DashboardReportDB__",
    indicies: [
      {
        name: "id", keyPath: "id", unique: true,
        autoIncrement: false, multiEntry: false,
      },
      {
        name: "description", keyPath: "description", unique: false,
        autoIncrement: false, multiEntry: false,
      },
    ],
  },
];
export function PublicAppDatabaseSetting() {
  return {
    databaseName: "AkwaabaFormsPublicDB__",
    databaseVersion: 1,
    stores: {
      list: databaseStores,
      login: databaseStores[0].storeName,
      redirectTo: databaseStores[1].storeName,
      offlinePosts: databaseStores[2].storeName,
      menu: databaseStores[3].storeName,
      projects: databaseStores[4].storeName,
      projectQuestionnaires: databaseStores[5].storeName,
      projectQuestionnaireTypes: databaseStores[6].storeName,
      projectQuestionnaireFormGroups: databaseStores[7].storeName,
      projectQuestionnaireAgeBrackets: databaseStores[8].storeName,
      projectQuestionnairePositionDescriptions: databaseStores[9].storeName,
      questionTypes: databaseStores[10].storeName,
      projectQuestionnaireQuestionOptions: databaseStores[11].storeName,
      projectQuestionnaireQuestions: databaseStores[12].storeName,
      projectQuestionnaireQuestionResponses: databaseStores[13].storeName,
      questionnaireResponses: databaseStores[14].storeName,
      walletInvoice: databaseStores[15].storeName,
      dashboardReport: databaseStores[16].storeName,
    },
  }
}