import { CONSTANTS } from "@@addons/constants";
import { ApDbCallback_I } from "@@addons/interfaces/database";
import { LoginStore_I } from "@@addons/interfaces/database/login";
import { PublicLoginStore_I } from "@@addons/interfaces/database/login/public";
import { DateTime } from "luxon";
import { AppDatabase } from '..';
import { IfRedirect } from "../redirect_to/if_redirect";

export class LoginStore extends AppDatabase<LoginStore_I | PublicLoginStore_I> {
  private db = this;

  public constructor() {
    super();

    this.storeTable = this.db.LoginDB__;
  }

  /**
   * getId
   */
  public getId(callback: ApDbCallback_I<number>) {
    return this.getAll(
      (res) => {
        const id = res.length > 0 ? res[0].id : 0;
        callback(id);
      },
    );
  }

  /**
   * make post
   */
  public async makePost(
    object: LoginStore_I[] | PublicLoginStore_I[],
    callback?: ApDbCallback_I<number>) {
    const _this = this;
    this.getAll(
      (res) => {
        res.forEach(login => {
          _this.removeFrom(login.id, (dRes) => {
            // dRes
            // console.error({ dRes });
          });
        });
      },
    );
    super.makePost(object, callback)
  }

  /**
   * formatRawData
   */
  public formatRawData(data: any) {
    const proccessed: LoginStore_I = {
      id: Number(data['id']),
      accountId: Number(data['accountId']),
      firstname: String(data['firstname']),
      middleName: String(""),
      surname: String(data['surname']),
      gender: Number(data['gender']),
      dateOfBirth: String(data['dateOfBirth']),
      profilePicture: String(data['profilePicture']),
      email: String(data['email']),
      phone: String(data['phone']),
      date: String(data['date']),
      loginDate: DateTime.now().toJSDate(),
      rememberMe: Boolean(data['rememberMe'])
    };
    return proccessed;
  }

  /**
   * formatRawPublicData
   */
  public formatRawPublicData(data: any) {
    console.log({ data });

    // const accountType = Number(data['accountId']),
    const accountType = Number(data['accountType']),
      proccessed = accountType === 1
        ? this.formatRawPublicIndividualData(data)
        : this.formatRawPublicOrganizationData(data);
    return proccessed;
  }

  /**
   * formatRawPublicIndividual AccountData 
   */
  public formatRawPublicIndividualData(data: any) {
    const proccessed: PublicLoginStore_I = {
      id: Number(data['id']),
      accountType: Number(data['accountType']),
      firstname: String(data['firstname']),
      middlename: String(data['middlename']),
      lastname: String(data['lastname']),
      gender: Number(data['gender']),
      genderInfo: {
        id: Number(data['genderInfo']['id']),
        name: String(data['genderInfo']['name'])
      },
      dateofbirth: String(data['dateofbirth']),
      profilePhoto: String(data['profilePhoto']),
      email: String(data['email']),
      phone: String(data['phone']),
      whatsapp: String(data['whatsapp']),
      disability: Boolean(data['disability']),
      educationStatus: Number(data['educationStatus']),
      educationStatusInfo: {
        id: Number(data['educationStatusInfo']['id']),
        name: String(data['educationStatusInfo']['name']),
        accountId: Number(data['educationStatusInfo']['accountId']),
        accountType: Number(data['educationStatusInfo']['accountType']),
      },
      maritalStatus: Number(data['maritalStatus']),
      maritalStatusInfo: {
        id: Number(data['maritalStatusInfo']['id']),
        name: String(data['maritalStatusInfo']['name'])
      },
      occupationStatus: Number(data['occupationStatus']),
      occupationStatusInfo: {
        id: Number(data['occupationStatusInfo']['id']),
        name: String(data['occupationStatusInfo']['name']),
        accountId: Number(data['occupationStatusInfo']['accountId']),
        accountType: Number(data['occupationStatusInfo']['accountType']),
      },
      profession: Number(data['occupationStatus']),
      professionInfo: {
        id: Number(data['professionInfo']['id']),
        name: String(data['professionInfo']['name']),
        accountId: Number(data['professionInfo']['accountId']),
        accountType: Number(data['professionInfo']['accountType']),
      },
      country: Number(data['country']),
      countryInfo: data['countryInfo'] === undefined ? null : {
        id: Number(data['countryInfo']['id']),
        name: String(data['countryInfo']['name']),
        short: String(data['countryInfo']['short']),
        code: String(data['countryInfo']['code']),
      },
      state_province: String(data['state_province']),
      region: Number(data['region']),
      regionInfo: data['regionInfo'] === undefined ? null : {
        id: Number(data['regionInfo']['id']),
        location: String(data['regionInfo']['location']),
      },
      district: Number(data['district']),
      districtInfo: data['districtInfo'] === undefined ? null : {
        id: Number(data['districtInfo']['id']),
        location: String(data['districtInfo']['location']),
      },
      constituency: Number(data['constituency']),
      constituencyInfo: data['constituencyInfo'] === undefined ? null : {
        id: Number(data['constituencyInfo']['id']),
        location: String(data['constituencyInfo']['location']),
      },
      electoralarea: Number(data['electoralarea']),
      electoralareaInfo: data['electoralareaInfo'] === undefined ? null : {
        id: Number(data['electoralareaInfo']['id']),
        location: String(data['electoralareaInfo']['location']),
      },
      community: String((data['community'] === undefined) || (data['community'] === null) || (data['community'] === "null") ? "" : data['community']),
      verifiedEmail: String(data['verifiedEmail']),
      verified: Boolean(data['verified']),
      lastActive: String(data['lastActive']),
      date: String(data['date']),
      loginDate: DateTime.now().toJSDate(),
      rememberMe: Boolean(data['rememberMe'])
    };
    return proccessed;
  }

  /**
   * formatRawPublicOrganization AccountData 
   */
  public formatRawPublicOrganizationData(data: any) {
    const proccessed: PublicLoginStore_I = {
      id: Number(data['id']),
      accountType: Number(data['accountType']),
      organizationName: String(data['organizationName']),
      logo: String(data['logo']),
      contactPersonName: String(data['contactPersonName']),
      contactPersonEmail: String(data['contactPersonEmail']),
      contactPersonPhone: String(data['contactPersonPhone']),
      contactPersonWhatsapp: String(data['contactPersonWhatsapp']),
      contactPersonGender: Number(data['contactPersonGender']),
      contactPersonGenderInfo: {
        id: Number(data['contactPersonGenderInfo']['id']),
        name: String(data['contactPersonGenderInfo']['name'])
      },
      country: Number(data['country']),
      countryInfo: data['countryInfo'] === undefined ? null : {
        id: Number(data['countryInfo']['id']),
        name: String(data['countryInfo']['name']),
        short: String(data['countryInfo']['short']),
        code: String(data['countryInfo']['code']),
      },
      state_province: String(data['state_province']),
      region: Number(data['region']),
      regionInfo: data['regionInfo'] === undefined ? null : {
        id: Number(data['regionInfo']['id']),
        location: String(data['regionInfo']['location']),
      },
      district: Number(data['district']),
      districtInfo: data['districtInfo'] === undefined ? null : {
        id: Number(data['districtInfo']['id']),
        location: String(data['districtInfo']['location']),
      },
      constituency: Number(data['constituency']),
      constituencyInfo: data['constituencyInfo'] === undefined ? null : {
        id: Number(data['constituencyInfo']['id']),
        location: String(data['constituencyInfo']['location']),
      },
      electoralarea: Number(data['electoralarea']),
      electoralareaInfo: data['electoralareaInfo'] === undefined ? null : {
        id: Number(data['electoralareaInfo']['id']),
        location: String(data['electoralareaInfo']['location']),
      },
      community: String(data['community'] === undefined ? "" : data['community']),
      verifiedEmail: String(data['verifiedEmail']),
      verified: Boolean(data['verified']),
      lastActive: String(data['lastActive']),
      date: String(data['date']),
      loginDate: DateTime.now().toJSDate(),
      rememberMe: Boolean(data['rememberMe'])
    };
    return proccessed;
  }

  /**
   * formatRawPublicLocationData Location Data 
   */
  public formatRawPublicLocationData(data: any) {
    const proccessed: PublicLoginStore_I = {
      region: Number(data['region']),
      regionInfo: data['regionInfo'] === undefined ? null : {
        id: Number(data['regionInfo']['id']),
        location: String(data['regionInfo']['location']),
      },
      district: Number(data['district']),
      districtInfo: data['districtInfo'] === undefined ? null : {
        id: Number(data['districtInfo']['id']),
        location: String(data['districtInfo']['location']),
      },
      constituency: Number(data['constituency']),
      constituencyInfo: data['constituencyInfo'] === undefined ? null : {
        id: Number(data['constituencyInfo']['id']),
        location: String(data['constituencyInfo']['location']),
      },
      electoralarea: Number(data['electoralarea']),
      electoralareaInfo: data['electoralareaInfo'] === undefined ? null : {
        id: Number(data['electoralareaInfo']['id']),
        location: String(data['electoralareaInfo']['location']),
      },
      community: String(data['community'] === undefined ? "" : data['community']),
    };
    return proccessed;
  }

  /**
   * loggedIn
   */
  public loggedIn() {
    this.expired();
    const ifRedirect = new IfRedirect();
    this.getAll(
      async (res) => {
        // console.log({
        //   "res.length": res.length,
        //   "res.length.valueOf()": res.length.valueOf(),
        // });
        if (res.length > 0) {
          res.forEach(async login => {
            if (window.location.href === CONSTANTS.URLS.LOGIN_PAGE_BASE_URL) {
              if (CONSTANTS.APP_SETTINGS.isClientApp) {
                window.location.href = CONSTANTS.URLS.HOME_PAGE_BASE_URL;
              } else {
                window.location.href = CONSTANTS.URLS.PUBLIC_PAGE_BASE_URL;
              }
            } else {
              const redirectUrl = await ifRedirect.getUrl();
              // console.log({"redirectUrl": redirectUrl});

              if (redirectUrl !== null) {
                const URL = redirectUrl.url;
                await ifRedirect.removeUrl();
                window.location.href = URL;
              }
            }
          });
        } else {
          if (window.location.href !== CONSTANTS.URLS.LOGIN_PAGE_BASE_URL) {
            await ifRedirect.setUrl(window.location.href);
            window.location.href = CONSTANTS.URLS.LOGIN_PAGE_BASE_URL;
          }
        }

      },
    );
  }

  /**
   * expired
   */
  public expired() {
    const _this = this;
    this.getAll(
      (res) => {
        res.forEach(async login => {
          if (login.rememberMe === false) {
            let current: DateTime = DateTime.now(),
              loginDate: DateTime = DateTime.fromJSDate(login.loginDate);
            let difference = Math.abs(loginDate.diff(current, 'hours').hours);
            // console.log({ difference });

            if (difference > 24) {
              await _this.removeFrom(login.id, (dRes) => {
                // dRes
                console.error({ dRes });
              });
            }
          }
        });
      },
    );
  }
}