import { AppDatabaseSetting } from "@@addons/database/settings";
import { PublicAppDatabaseSetting } from "@@addons/database/settings/public";
// console.log({ "window.location.href": window.location.href });

// const PRODUCTION = false;
const PRODUCTION = true;
let _HOME_PAGE_BASE_URL: string;
if (PRODUCTION) {
  // _HOME_PAGE_BASE_URL = "akwaaba.akwaabasoftware.com/";
  _HOME_PAGE_BASE_URL = "adminform.akwaabasoftware.com/";
} else {
  _HOME_PAGE_BASE_URL = "akwaaba-forms/";
}

let _isClientApp: boolean;
if (window.location.href.includes(_HOME_PAGE_BASE_URL)) {
  _isClientApp = true;
} else {
  _isClientApp = false;
}

// console.log({ _isClientApp });

export const APP_SETTINGS = {
  production: PRODUCTION,
  isClientApp: _isClientApp,
  database: _isClientApp
    ? AppDatabaseSetting() : PublicAppDatabaseSetting(),
}
