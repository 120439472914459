import { ApDbCallback_I } from "@@addons/interfaces/database";
import { RedirectToStore_I } from "@@addons/interfaces/database/redirect_to";
import { AppDatabase } from '..';

export class RedirectToStore extends AppDatabase<RedirectToStore_I> {
  private db = this;

  public constructor() {
    super();

    this.storeTable = this.db.RedirectToDB__;
  }

  /**
   * getId
   */
  public getId(callback: ApDbCallback_I<number>) {
    return this.getAll(
      (res) => {
        const id = res.length > 0 ? res[0].id : 0;
        callback(id);
      },
    );
  }

  /**
   * make post
   */
  public async makePost(
    object: RedirectToStore_I[],
    callback?: ApDbCallback_I<number>) {
    const _this = this;
    this.getAll(
      (res) => {
        res.forEach(redirectTo => {
          _this.removeFrom(redirectTo.id, (dRes) => {
            // dRes
            console.error({ dRes });
          });
        });
      },
    );
    super.makePost(object, callback)
  }
}