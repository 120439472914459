// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging/sw";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCbvfKRqsAWDpWeqxUqRtR3IXh10azmmIs",
  authDomain: "akwaaba-forms.firebaseapp.com",
  projectId: "akwaaba-forms",
  storageBucket: "akwaaba-forms.appspot.com",
  messagingSenderId: "130286367239",
  appId: "1:130286367239:web:179d8ebb1cf239d57330a2",
  measurementId: "G-N6C7125742"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const messaging = getMessaging(app);