import { AppMessenger } from '@@addons/classes/messenger_channel'
import { CONSTANTS } from '@@addons/constants'
import fetch from './time_out'
export async function http(input: RequestInfo, init?: RequestInit, akwaabaApi: boolean = false, jsonData: boolean = true) {
  const status = (response: any) => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response)
    } else {
      return Promise.resolve(response)
      // return Promise.reject(new Error(response))
    }
  }

  const json = (response: any) => {
    // console.log({ response });
    if (response.status === 204) {
      return { "success": true, "msg": "", data: {} };
    }
    
    return response.json()
  }
  // console.log({init});

  var _init: RequestInit = { method: "GET" };

  init = (init === null) || (init === undefined) ? _init : init;

  var myHeaders = new Headers();
  myHeaders.append("Access-Control-Allow-Origin", "*/*");
  myHeaders.append("Accept", "application/json, text/plain, */*");
  if (akwaabaApi) {
    myHeaders.append("Authorization", CONSTANTS.AUTHORIZATION.akwaabaApi);
  } else {
    myHeaders.append("Authorization", CONSTANTS.AUTHORIZATION.pdbApi);
  }
  if (jsonData) {
    myHeaders.append("Content-Type", "application/json");
  }


  // var myHeaders = {
  //   "Access-Control-Allow-Origin":"*/*",
  //   "Accept":"application/json, text/plain, */*",
  //   "Authorization":"Bearer dskjsdr43930934j34934304934n34kkd3d89sdjsdjb349",
  // }
  init.headers = myHeaders;
  init.mode = "cors";
  init.redirect = 'follow'
  // const controller = new AbortController();
  // init.signal = controller.signal;
  // init.redirect = 'follow';
  // console.log({ init });
  const aM = new AppMessenger<boolean>('http-request');
  // @ts-ignore
  if (window['http-requests'] === undefined) {
    // @ts-ignore
    window['http-requests'] = 0;
  } else {
    // @ts-ignore
    window['http-requests'] += 1;
  }
  // // @ts-ignore
  // console.log({ "window['http-requests']": window['http-requests'] });

  aM.post(false);
  return await fetch(input, init, 300000)
    .then(status)
    .then(json)
    .then(async (data) => {
      // @ts-ignore
      if (window['http-requests'] !== undefined) {
        // @ts-ignore
        window['http-requests'] -= 1;

        // @ts-ignore
        window['http-requests'] = window['http-requests'] < 0
          // @ts-ignore
          ? 0 : window['http-requests'];
      }
      // @ts-ignore
      if (window['http-requests'] === 0) {
        aM.post(true);
      }
      // // @ts-ignore
      // console.log({ "window['http-requests']": window['http-requests'] });
      // console.log('Request succeeded with JSON response', data);
      return data;
    }).catch(function (error) {
      console.error('Request failed', error);
      throw error;
    });
}