import { RedirectToStore_I } from "@@addons/interfaces/database/redirect_to";
import { DateTime } from "luxon";
import { RedirectToStore } from ".";

export class IfRedirect {
  private redirectTo: RedirectToStore;

  constructor() {
    this.init();
  }

  private init() {
    this.redirectTo = new RedirectToStore();
  }

  public async getUrl() {
    let id: number;
    await this.redirectTo.getId((res) => {
      id = res;
    });

    let redirect: RedirectToStore_I = null;
    await this.redirectTo.getOne(id, (res) => {
      redirect = res;
    });

    return redirect;
  }

  public async setUrl(url: string) {
    const date = DateTime.now().toJSDate();
    return await this.redirectTo.makePost([{ id: 1, url: url, date: date }], (res) => {
      return res;
    });
  }

  public async removeUrl() {
    await this.redirectTo.removeAll();
  }

  public async redirect() {
    await this.getUrl().then((value) => {
      if (value !== null) {
        window.location.href = value.url;
      }
    });
  }
}